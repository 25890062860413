@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-editor {
    background: #fff;
    border-radius: 4px;
    border: 1px solid $brand-grey-light;
    padding: 0.42rem;
    font-family: $ff-body;
    font-weight: $fw-base;

    &:focus {
        outline: -webkit-focus-ring-color auto 1px;
    }

    &:hover {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25);
    }
}

.ql-disabled {
    opacity: 0.75;
}

.ql-bubble .ql-tooltip {
    z-index: 2;
}
