.Input {
    font-family: $ff-body;
    font-weight: $fw-base;
    background: $brand-white;
    border: 1px solid $brand-grey-light;
    border-radius: 4px;
    box-shadow: $shadow;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    padding: 0.4rem;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25);
    }

    &:is(select) {
      cursor: pointer;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        box-shadow: 0 0 4px rgba(102, 175, 233, 0.15);
    }

    &:disabled {
        opacity: 0.75;
    }

    &.is-invalid {
        color: $notice-fail;
        border-color: $notice-fail;
    }

    &--material {
        padding: 6px;
    }

    &--multiline {
        min-height: 4rem;
        padding: 0.5em 1em;
        border-width: 1px;
        resize: vertical;
    }

    &--select {
        $arrow: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 255 255'%3e%3cpath fill='%23666' d='M0 63.75l127.5 127.5L255 63.75z'/%3e%3c/svg%3e";
        appearance: none;
        background: $brand-white url($arrow) right 0.5rem center no-repeat;
        padding-right: 2rem;

        &::-ms-expand {
            display: none;
        }
    }

    &--icon {
        position: absolute;
        right: 0;
    }

    &--naked {
        border: none;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
    }

    &--daterange {
        font-size: 13px;
        padding: 6px;
    }

    &--optgroup {
        color: $brand-red;
        font-weight: bold;
    }

    &--grouped {
        span {
            display: none;
        }
    }

    &--small {
        padding: 0.3rem;
        font-size: 11px;
    }

    &--tableEditable {
        border: none;
        border-radius: 0;
        padding: 0 !important;
        background: transparent;
        font-size: 11px;
        line-height: 15px;
        outline: none;
        resize: none;
        box-shadow: none;
    }

    &--hiddenDatePickerInput {
        visibility: hidden;
        height: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
    }

    &[type='checkbox'] {
      box-shadow: none;
    }
}
