@use 'sass:map';
@import '~@angular/material/theming';

/** Include the core Angular Material styles */
@include mat-core();

$bv-brand: (
    50: #f9e2e5,
    100: #f0b7be,
    200: #e68893,
    300: #db5868,
    400: #d43447,
    500: #cc1027,
    600: #c70e23,
    700: #c00c1d,
    800: #b90917,
    900: #ad050e,
    A100: #ffd8d9,
    A200: #ffa5a7,
    A400: #ff7275,
    A700: #ff595c,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$bv-orange: (
    50: #ffffff,
    100: #fff7f4,
    200: #fecdbd,
    300: #fc9977,
    400: #fc8259,
    500: #fb6c3b,
    600: #fa551d,
    700: #f44205,
    800: #d63a04,
    900: #b83204,
    A100: #ffffff,
    A200: #fff7f4,
    A400: #fc8259,
    A700: #f44205,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    ),
);

$bv-palette-primary: mat-palette($bv-brand);
$bv-palette-accent: mat-palette($bv-orange);

// include the custom theme components into a theme object
$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);

@include angular-material-theme($bv-theme);

// Component overrides
.mat-icon-button {
    width: 35px!important;
    height: 35px!important;
    line-height: 35px!important;
}

.material-icons {
    font-size: 20px;
}

.dialog-actions {
    flex-wrap: nowrap;
}

.mat-chip {
    &.mat-standard-chip {
        border-radius: 2px;
        font-size: 11px;
        margin: 0;
        padding: 2px 6px;
        height: 20px;
        min-height: auto;
    }
}

.mat-dialog-container {
    padding: 0 24px !important;
}

.mat-dialog-content {
    padding: 24px !important;
    max-height: 85vh !important;
}

.mat-select-value {
    max-width: initial !important;
    font-weight: normal;
}

.mat-optgroup {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 16px;
        right: 1rem;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #2f2f2f;
        font-size: 0;
        line-height: 0;
    }

    &.active {
        &:after {
            transform: rotate(180deg);
        }
    }

    .mat-option {
         display: none;

          &.active {
              display: flex !important;
          }
    }
 }

 .mat-optgroup-label {
    cursor: pointer;
    border-left: 2px solid $brand-red;
    background: rgba($brand-red, 0.03);
 }


 .mat-option, .mat-optgroup-label {
    color: #000;
 }

 .mat-option {
    font-family: $ff-body;
    font-weight: $fw-base;
    font-weight: normal!important;
    height: 30px !important;
    font-size: 13px!important;
 }

 .mat-menu-item {
    line-height: 36px!important;
    height: 36px!important;
 }
