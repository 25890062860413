.Page {
    height: 100%;
    padding: 0;
    position: relative;

    @media (max-width: $desktop-sm) {
      height: auto;
      padding-bottom: 20px;
    }

    @media (min-width: $desktop-sm) {
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 60px;
    }

    &-container {
        max-width: 1800px;
        margin: 0 auto 2rem;
    }

    &-cell {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
