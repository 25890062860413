.Checkbox {
    position: relative;
    display: flex;
    align-items: center;

    &-control {
        position: absolute;
        opacity: 0;
    }

    &-check {
        @include size(1rem);
        position: relative;
        display: inline-block;
        border: 1px solid $brand-grey-light;
        border-radius: 3px;
        background-color: $brand-white;
        text-indent: -9999px;
        box-shadow: $shadow;
        cursor: pointer;

        &::before {
            @include position(absolute, 0);
            content: '';
            opacity: 0;
            left: 6px;
            top: 0px;
            width: 5px;
            height: 12px;
            border: solid black;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }

        :checked + &::before {
            opacity: 1;
        }

        :focus + & {
            box-shadow: 0 0 6px rgba($brand-green, 0.5);
        }

        :disabled + & {
            background: $brand-grey-lighter;
            opacity: .5;
            cursor: not-allowed;
        }
    }

    &-label {
        display: inline-block;
        margin-left: .5rem;
        margin-bottom: 0;
        line-height: 10px;

        :disabled ~ & {
            opacity: .75;
            cursor: not-allowed;
        }
    }
}

.Checkbox + .Checkbox {
    margin-top: .5rem;
}
