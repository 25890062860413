.Table {
    $root: this;
    border-collapse: collapse;
    $table-border-radius: 5px;
    $table-bg: $brand-grey-lighter;
    $table-border: $brand-white;

    width: 100%;
    font-size: 11px;
    line-height: 1;
    border-spacing: 0;

    &-head {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 2;
        height: 40px;
        box-shadow: 0px 2px 1px rgb(0 0 0 / 20%);
        //border-bottom: 2px solid $brand-grey-light;
    }

    &-row {
        transition: background 0.3s;

        &--hide {
            display: none;
        }

        &--divider {
            border-top: 10px solid $brand-grey-lighter;
        }

        &--clickable {
            cursor: pointer;
        }

        // &--highlight {
        //     color: $brand-green-dark;
        // }

        &--groupitem {
            display: none;
        }

        &--groupexpanded {
            border-left: 2px solid $brand-red;
            background: rgba($brand-red, 0.03);
            font-weight: bold;
        }

        &--groupshow {
            border-left: 2px solid $brand-red;
            background: rgba($brand-red, 0.03);
        }

        &--groupshow {
            display: table-row;
        }

        &--editing,
        &:hover {
            background: rgba($brand-red, 0.1);
        }

        &:hover {
            .hover-button {
                opacity: 1;
            }
        }

        box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);

        &-status {
            display: inline-block;
            color: transparent;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 10px;
            left: 10px;
        }

        &--icon {
            .Table-row-status {
                color: #000;
            }
        }

        &--warn {
            .Table-row-status {
                color: darkorange;
                svg .cls-1 {
                    fill: darkorange;
                }
            }

            .hl-color {
                color: darkorange;
            }
        }

        &--alert {
            .Table-row-status {
                color: red;
                svg .cls-1 {
                    fill: red;
                }
            }

            .hl-color {
                color: red;
            }
        }

        &--dormant {
            color: $brand-grey !important;
        }

        &--highlight {
            border: 1px solid $brand-green-dark;
            animation: borderPulse 2s forwards;
        }

        @keyframes borderPulse {
            20% {
                border-color: $brand-green-dark;
            }
            40% {
                border-color: rgba($brand-green-dark, 0.2);
            }
            60% {
                border-color: $brand-green-dark;
            }
            80% {
                border-color: rgba($brand-green-dark, 0.2);
            }
            100% {
                border-color: $brand-green-dark;
            }
        }
    }

    &-cellHead {
        padding: 0.5rem;
        text-align: left;
        position: relative;
        white-space: nowrap;

        button:not(.disabled) {
            transition: color 0.3s;
        }

        &:hover {
          button:not(.disabled):not(.active) {
              color: rgba($brand-red, 0.7);
          }
      }
    }

    &-cellHead + &-cellHead {
        padding-left: 5px;
    }

    &-cellData {
        padding: 12px 10px 10px;
        position: relative;
        vertical-align: top;
        line-height: 15px;

        &--actions {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .button {
                width: 35px;
                height: 35px;
                line-height: 35px;
            }

            .hover-button {
                opacity: 0;
                transition: all linear 0.1s;
            }
        }

        &--clickable {
            cursor: pointer;
        }

        &--alignRight {
            text-align: right;
        }

        .Table--striped:not(.Table--withHeading)
            .Table-body
            .Table-row:nth-child(odd)
            & {
            background: $table-bg;
            border-top: 2px solid $table-border;
            border-bottom: 2px solid $table-border;

            &:first-child {
                border-top-left-radius: $table-border-radius;
                border-bottom-left-radius: $table-border-radius;
            }

            &:last-child {
                border-top-right-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }
        }

        .Table--striped.Table--dark:not(.Table--withHeading)
            .Table-body
            .Table-row:nth-child(odd)
            & {
            background: $brand-grey-dark;
            border-top: 2px solid $brand-grey;
            border-bottom: 2px solid $brand-grey;
        }

        .Table--striped.Table--withHeading
            .Table-body
            .Table-row:nth-child(even)
            & {
            background: $table-bg;
            border-top: 2px solid $table-border;
            border-bottom: 2px solid $table-border;

            &:first-child {
                border-top-left-radius: $table-border-radius;
                border-bottom-left-radius: $table-border-radius;
            }

            &:last-child {
                border-top-right-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }
        }

        &--rightAlign {
            text-align: right;
        }
    }

    &-cellData + &-cellData {
        padding-left: 5px;
    }

    &-moreActionsBtn {
        padding: 0;
        margin: 0;
        background: transparent;
        border: none;
    }

    &-cellBadge {
        background: #ccc;
        padding: 1px 6px;
        display: inline-block;
        margin-left: 5px;
        border-radius: 2px;
    }

    &-cell {
        &--status {
            width: 1rem;
            text-align: right;
        }
    }

    &-heading {
        background: $brand-black;
        color: $brand-white;
        font-size: 0.65rem;
        line-height: 1.1;
        text-align: left;
        padding: 7px 10px;
    }

    &-status {
        &--green {
            color: $notice-pass;
        }

        &--amber {
            color: $notice-warn;
        }

        &--red {
            color: $notice-fail;
        }
    }

    &-sort {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
            position: relative;
            display: flex;
            align-items: center;
            font-family: $ff-body;
            font-size: 0.65rem;
            font-weight: bold;
            padding: 0;
            background: transparent;
            border: none;
            width: 100%;
            text-align: left;
            cursor: pointer;
        }

        .active {
            color: $brand-red;

            &:after {
                content: '';
                position: relative;
                display: inline-flex;
                width: 5px;
                height: 5px;
                border-top: 2px solid $brand-red;
                border-left: 2px solid $brand-red;
                transform: rotate(45deg) translate(8px, -6px);
            }

            &.desc {
                &:after {
                    transform: rotate(-135deg) translate(-6px, 8px);
                }
            }
        }

        .disabled {
            cursor: default;
            pointer-events: none;
            color: #000;

            &:after {
                display: none;
            }
        }
    }

    &--no-bg-hover {
        .Table-row {
            &:hover {
                background: none;
            }
        }
    }
}

// Baseline styles

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $table-cell-padding;
                line-height: 1.3;
                vertical-align: top;
                border-top: 1px solid $table-border-color;
            }
        }
    }
    // Bottom align for column headings
    > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $table-border-color;
    }
    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: 2px solid $table-border-color;
    }
    // Nesting
    .table {
        background-color: $brand-white;
    }
}

// Condensed table w/ half padding

.table-condensed {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $table-condensed-cell-padding;
            }
        }
    }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: 1px solid $table-border-color;

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 1px solid $table-border-color;
            }
        }
    }

    > thead > tr {
        > th,
        > td {
            border-bottom-width: 2px;
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    > tbody > tr:nth-of-type(odd) {
        background-color: $table-bg-accent;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    > tbody > tr:hover {
        background-color: $table-bg-hover;
    }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*='col-'] {
    position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
    float: none;
    display: table-column;
}

table {
    td,
    th {
        &[class*='col-'] {
            position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
            float: none;
            display: table-cell;
        }
    }
}
