.Form {
    &-heading {
        font-family: $ff-body;
        font-size: $fs-h3;
        margin: 0 0 1.5rem;
    }

    &-section {
        background: $brand-grey-lighter;
        border-radius: 5px;
        padding: 0.75rem;
    }

    &-row {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &-group + &-group {
        margin-top: 1rem;
    }

    &-fieldset {
        border-radius: 4px;
        border: 1px solid $brand-grey-light;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: .5rem;
    }

    &-fieldset + &-fieldset {
        margin-top: 0.5rem;
    }

    &-field {
        display: flex;
        position: relative;
        flex-direction: column;

        &--row {
            flex-direction: row;
        }

        .Form-row > & {
            flex: 1 1 100%;

            @include breakpoint($desktop) {
                flex-basis: 0%;
            }
        }
    }

    &-control {
        position: relative;
        display: flex;
        flex: 1;

        .Form-label + & {
            //margin-left: 0.8rem;
        }
    }

    &-label {
        font-size: 0.65rem;
        flex-shrink: 0;
        margin-bottom: 0.25rem;

        &--center {
            margin-bottom: 0;
        }
    }

    &-error {
        font-size: 0.7rem;
        color: $brand-red;
        margin-top: 0.15rem;
    }

    &-actions {
        width: 100%;
        margin: 1rem 0 0;

        > * + * {
            margin-left: 0.5rem;
        }

        &-group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            div > * + * {
                margin-left: 0.5rem;
            }

            > div:nth-of-type(2) {
              display: flex;
              flex-wrap: nowrap;
            }
        }
    }

    &--valid {
        border-left: 3px solid $brand-green; /* green */
    }

    &--invalid {
        border-left: 3px solid $brand-red; /* red */
    }
}

.ng-valid[required]:not(user-select):not(radio-picker),
.ng-valid.required {
    border-left: 3px solid $brand-green; /* green */
}

.ng-invalid:not(form):not(quill-editor):not(.FinanceItems-item-wrap):not(
        user-select
    ):not(radio-picker) {
    border-left: 3px solid $brand-red; /* red */
}
