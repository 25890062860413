/* You can add global styles to this file, and also import other style files */

// Vendor
@import '~bourbon/core/bourbon';
@import '~breakpoint-sass/stylesheets/breakpoint';

// Tools
@import 'assets/styles/tools/_zindex';

// Settings
@import 'assets/styles/settings/_helpers';
@import 'assets/styles/settings/_breakpoints';
@import 'assets/styles/settings/_dimensions';
@import 'assets/styles/settings/_swatches';
@import 'assets/styles/settings/_typography';
@import 'assets/styles/settings/_zindex';

// Utilities
@import 'assets/styles/utilities/_accessibility';
@import 'assets/styles/utilities/_font-family';
@import 'assets/styles/utilities/_hide';
@import 'assets/styles/utilities/_inherit-height';
@import 'assets/styles/utilities/_lists';
@import 'assets/styles/utilities/_input';
@import 'assets/styles/utilities/_position';
@import 'assets/styles/utilities/_spacers';
@import 'assets/styles/utilities/_text-align';
@import 'assets/styles/utilities/_typography';
@import 'assets/styles/utilities/_unset';

// Vendor
@import 'assets/styles/vendor/_quill';
@import 'assets/styles/vendor/_material';

// Base
@import 'assets/styles/base/_body';
@import 'assets/styles/base/_typography';
@import 'assets/styles/base/_anchors';

// Objects
@import 'assets/styles/objects/_card';
@import 'assets/styles/objects/_grid';
@import 'assets/styles/objects/_page';
@import 'assets/styles/objects/_form';
@import 'assets/styles/objects/_section';
@import 'assets/styles/objects/_table';
@import 'assets/styles/objects/_modal';

// Global Components
@import 'assets/styles/components/forms/_input';
@import 'assets/styles/components/forms/_checkbox';
@import 'assets/styles/components/forms/_radio';
@import 'assets/styles/components/_button';
@import 'assets/styles/components/_alert';
@import 'assets/styles/components/_tooltip';
@import 'assets/styles/components/_icons';

html,
body {
    height: 100%;
}
