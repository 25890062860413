.Card {
    $this: &;
    background: #fff;
    border-radius: 6px;
    box-shadow: $shadow;
    font-size: 13px;

    &--with-tabs {
        border-top-left-radius: 0;
    }

    &-title {
        display: flex;
        font-weight: bold;
        justify-content: space-between;

        #{$this}--pad-10 & {
            font-size: 16px;
            padding: 10px;
        }

        #{$this}--pad & {
            font-size: 20px;
            padding: 20px;
        }
    }

    &-subtitle {
        display: flex;
        position: relative;
        font-weight: bold;
        justify-content: space-between;

        #{$this}--pad-10 & {
            font-size: 13px;
            padding-left: 10px;
            padding-bottom: 5px;
        }

        #{$this}--pad & {
            font-size: 16px;
            padding-left: 20px;
            padding-bottom: 10px;
        }
    }

    &-body {
        list-style: none;
        margin: 0;

        li + li {
            padding-top: 5px;
        }

        #{$this}--pad-10 & {
            padding: 10px;
        }

        #{$this}--pad & {
            padding: 20px;
        }
    }

    &-buttons-wrapper {
        display: flex;
        gap: 0.5rem;
    }
}
