.u-input-unset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  appearance: none;
  color: inherit;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.u-button-unset {
  background: transparent;
  padding: 0;
}

.u-ul-unset {
    padding: 0;
    margin: 0;
    list-style: none;
}
