.u-ta-center {
    text-align: center !important;
}

.u-ta-left {
    text-align: left !important;
}

.u-ta-right {
    text-align: right !important;
}
