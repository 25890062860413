.Section {
    margin-bottom: 2rem;

    &-header {
        border-bottom: 1px solid $brand-grey-dark;
        padding: 0.3rem 0;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        &--topLevel {
            border-bottom: 2px solid $brand-red;
        }
    }

    &-title {
        font-family: $ff-body;
        font-size: 1rem;
        font-weight: $fw-bold;
        margin: 0;
    }

    &-subtitle {
        font-family: $ff-body;
        font-size: 1rem;
        font-weight: $fw-base;
        margin: 0;
    }
}
