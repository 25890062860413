.Grid {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    &--tablet-2 {
        @media (min-width: $tablet) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &--tablet-3 {
        @media (min-width: $tablet) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &--tablet-4 {
        @media (min-width: $tablet) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &--desktop-3 {
        @media (min-width: $desktop-sm) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &--desktop-4 {
        @media (min-width: $desktop-sm) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    &--desktop-5 {
        @media (min-width: $desktop-sm) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
    &--desktop-6 {
        @media (min-width: $desktop-sm) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }
}
