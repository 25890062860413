.hidden-input {
    background: transparent;
    border: none;
    font-family: $ff-body;
    outline: 1px solid transparent;
    transition: outline .3s ease;

    &:hover, &:focus {
        cursor: text;
        outline: 1px solid $brand-grey-light;
    }
}
