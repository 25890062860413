:root {
    font-size: $fs-mobile;

    @include breakpoint($tablet) {
        font-size: #{calc( 14px + (20 - 14) * (100vw - 680px) / (1680 - 680) )};
    }

    @include breakpoint($desktop-hd) {
        font-size: $fs-hd;
    }
}

html {
  scroll-behavior: smooth;

  &.modal-open  {
    overflow: hidden;

    .Page {
      overflow: hidden;
    }
  }
}

body {
    font-family: $ff-body;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
    background: #dadada;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
