.Button {
    $this: &;
    background: transparent;
    border: 2px solid $brand-grey-light;
    border-radius: 2rem;
    font-family: $ff-body;
    font-size: 0.75rem;
    padding: 8px 14px;
    text-align: left;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;

    &:not(&--link):not(&--selected):hover {
      background: $brand-grey-dark;
      border-color: $brand-grey-dark;
      color: $brand-white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }

    &--primary {
        background: $brand-green;
        border-color: $brand-green;
        color: $brand-white;
    }

    &.Button--primary:not(.Button--primary--link):hover {
        background: $brand-white;
        border-color: $brand-green-dark;
        color: $brand-green-dark;
        opacity: 1;
        box-shadow: 0 2px 5px rgba($brand-green-dark, 0.15);
    }

    &--secondary {
        background: $brand-white;
        border-color: $brand-grey-dark;
        color: $brand-black;
    }

    &.Button--secondary:not(.Button--primary--link):hover {
        background: $brand-grey-dark;
        border-color: $brand-grey-dark;
        color: $brand-white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }

    &--danger {
        background: $brand-red;
        border-color: $brand-red;
        color: $brand-white;
    }

    &.Button--danger:not(.Button--primary--link):hover {
      background: $brand-white;
      border-color: $brand-red;
      color: $brand-red;
      opacity: 1;
      box-shadow: 0 2px 5px rgba($brand-red, 0.15);
  }

    &--small {
        font-size: 0.6rem;
        padding: 6px 10px;
    }

    &--chip {
        font-size: 0.6rem;
        padding: 6px 10px;
        padding: 4px 10px;
        border-radius: 4px;
        border-width: 1px;

        &#{$this}--selected {
            background: green;
            border-color: green;
            color: #fff;
        }
    }

    &--loading {
        padding-right: 40px;

        .mat-spinner {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--primary,
    &--secondary,
    &--danger {
        border-width: 2px;

        &:not(&--link):hover {
            opacity: 0.8;
        }
    }

    &--link {
        border: none;
        padding: 0;
        text-decoration: underline;

        &:hover {
            color: $brand-red;
        }
    }

    &--center {
        text-align: center;
    }

    &--full-w {
        width: 100%;
    }

    &--selected {
        border-color: $brand-green;
    }

    &[disabled],
    &.Button[disabled]:not(.Button--primary--link):hover {
        background: $brand-grey-lighter;
        border-color: $brand-grey-lighter;
        color: $brand-black;
        opacity: 0.6;
        cursor: not-allowed;
        box-shadow: none;
    }

    &-action {
        background-color: $brand-grey;
        border: 1px solid transparent;
        border-radius: 100%;
        height: 27px;
        width: 27px;
        padding: 0;
        transition: background 0.2s ease, color 0.2s ease, border 0.2s ease;
        cursor: pointer;

        @media (min-width: $desktop-sm) {
            height: 25px;
            width: 25px;
        }

        &:hover:not(&--green):not(&--outline) {
            background-color: $brand-white;
            border-color: $brand-grey;
        }

        &--green {
            background: green;

            &:hover {
                opacity: 0.8;
            }
        }

        &--red {
            background: $brand-red !important;
            border-color: $brand-red;
            color: $brand-white;

            &:hover {
                opacity: 0.8;
            }
        }

        &--outline {
            border: 1px solid $brand-grey;
            background: #fff;

            &:hover {
                background: $brand-grey-lighter;
            }
        }

        &--transparentBg {
            background: transparent !important;
            color: $brand-grey-mid !important;
            border: none !important;

            @media (min-width: $desktop-sm) {
                height: 20px;
                width: 20px;
            }

            &:hover {
                background: none;
            }
        }
    }

    &-actionIcon {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        transition: filter 0.2s ease;

        &--add {
            background-image: url('/assets/images/ico-add.svg');
        }

        &--remove {
            background-image: url('/assets/images/ico-subtract.svg');
        }

        &--edit {
            background-image: url('/assets/images/ico-edit.svg');
            filter: brightness(1.5);
        }

        &--addcontact {
            background-image: url('/assets/images/ico-addcontact.svg');
            filter: brightness(1.5);
        }

        &--settings {
            background-image: url('/assets/images/ico-settings.svg');
        }

        &--info {
          background-image: url('/assets/images/ico-info.svg');
      }

        &--arrowRight {
            background-image: url('/assets/images/ico-arrow-right.svg');
        }

        .Button-action--transparentBg & {
            background-size: 100% 100%;
        }

        .Button-action:hover & {
            //filter: brightness(50%);
        }

        .Button-action--transparentBg:hover & {
            filter: brightness(0);
        }

        .Button-action--white &,
        .Button-action--white &:hover {
            filter: brightness(100);
        }
    }

    &--wide {
        padding-left: 20px;
        padding-right: 20px;
    }

    &-navArrow {
        position: relative;
        background: transparent;
        overflow: hidden;
        text-indent: -2000px;
        width: 2rem;
        cursor: pointer;
        padding: initial;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 2px solid $brand-grey-light;

        &:hover,
        &:not(.Button--link):not(.Button--selected):hover {
            background: $brand-grey-dark;
            border-color: $brand-grey-dark;
            color: $brand-white;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

            &:after {
              border-color: $brand-white;
            }
        }

        &:after {
            content: '';
            width: 0.5rem;
            height: 0.5rem;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            transform: rotate(45deg);
            position: relative;
        }

        &--prev {
            &::after {
                left: 2px;
                transform: rotate(45deg);
            }
        }

        &--next {
            &::after {
                right: 2px;
                transform: rotate(-135deg);
            }
        }

        &.is-disabled {
            cursor: not-allowed;

            &:not(.Button--link):not(.Button--selected):hover {
                background-color: transparent;
                border: 2px solid $brand-grey-light;
                box-shadow: none;

                &::after {
                  border-color: #000;
                }
            }

            &::after {
                opacity: 0.2;
            }
        }
    }

    &--download {
      width: 36px;
      height: 36px;
      padding: 0;
      border-radius: 8px;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('/assets/images/ico-download.svg');
          position: absolute;
          z-index: 1;
          width: 25px;
          height: 25px;
          top: 47%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
}
