// This is where you can place any variables for known exact measurements
// found across the website
//
// e.g. $headerSize: 44px;
//
// By declaring these values as variables, we avoid the confusion of magic
// numbers, and reduce likelihood of introducing regression issues.
//


// Tables
$table-cell-padding: 8px;
$table-condensed-cell-padding: 5px;
