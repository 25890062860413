.u-rel {
    position: relative;
}

.u-full {
    @include position(absolute, 0);
}

.u-center {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.u-bg-cover {
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
}

.u-object-cover {
    @include size(100%);
    object-fit: cover;
    object-position: center;
}

.u-behind {
    z-index: -1;
}

.u-above {
    z-index: 1;
}

.u-crop {
    overflow: hidden;
}

.u-overflow-auto {
  overflow: auto;
}

.u-w100 {
    width: 100%;
}

.u-h100 {
    height: 100%;
}

.u-float-left {
    float: left;
}

.u-float-right {
    float: right;
}

.u-inline-start {
    display: flex;
    align-items: flex-start;
}

.u-inline-center {
    display: flex;
    align-items: center;
}

.u-inline-end {
    display: flex;
    align-items: flex-end;
}

.u-justify-center {
    display: flex;
    justify-content: center;
}

.u-justify-between {
    display: flex;
    justify-content: space-between;
}

.u-justify-start {
    display: flex;
    justify-content: flex-start;
}

.u-justify-end {
    display: flex;
    justify-content: flex-end;
}

.u-grow {
    flex-grow: 1;
}

.u-no-shrink {
    flex-shrink: 0;
}

.u-wrap {
    flex-wrap: wrap;
}
